<template>
  <div class="bg-white px-25 py-25 md:px-65 md:py-40 rounded-5 relative">
    <!-- S I G N  U P  S T E P -->
    <div v-if="!showVerification">
      <div class="mb-40">
        <h1 class="text-dark-cl-20 text-1xl sm:text-3xl mb-25">Welcome to CEB Connects!</h1>
        <!-- TODO change laguage will be implemented later -->
        <!-- <p class="text-primary mb-25">Change language</p> -->
        <p class="text-grey-fp-60 text-sm sm:text-base">
          We need to collect some quick information but don’t worry within minutes you will be launching into success
        </p>
      </div>

      <!-- F O R M -->
      <form
        data-test="signup-form"
        @submit.prevent="onSubmit"
      >
        <div class="flex flex-col sm:flex-row items-center mb-25">
          <AppInput
            v-model="v$.firstName.$model"
            name="firstName"
            label="First Name"
            class="sm:mr-7 mb-10 sm:mb-0 w-full"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.firstName"
            @focus="handleFocus('firstName')"
          />
          <AppInput
            v-model="v$.lastName.$model"
            name="lastName"
            label="Last Name"
            class="sm:ml-7 w-full"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.lastName"
            @focus="handleFocus('lastName')"
          />
        </div>

        <AppInput
          v-model="v$.email.$model"
          name="email"
          label="Gmail Address"
          class="mb-25"
          :size="screen.width < 640 ? 'medium' : 'large'"
          :error="errorMessages.email || customError"
          @focus="handleFocus('email')"
          @blur="validateField('email')"
        />

        <div class="flex items-center mb-20">
          <p class="text-grey-fp-60 text-sm sm:text-base">
            <span>Need a Google Gmail Email Address?</span>
            <a
              href="https://accounts.google.com/signup/v2/webcreateaccount?flowName=GlifWebSignIn&flowEntry=SignUp"
              class="text-primary cursor-pointer"
              target="_blank"
            >
              Sign Up for free.
            </a>
          </p>
        </div>

        <div>
          <AppInput
            v-model="v$.password.$model"
            name="passoword"
            label="Password"
            type="password"
            class="mb-25"
            use-hint
            :hintOptions="hintOptions"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.password"
            @focus="handleFocus('password')"
            @blur="validateField('password')"
          />

          <AppInput
            v-model="v$.confirmPassword.$model"
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            class="mb-25"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.confirmPassword"
            @focus="handleFocus('confirmPassword')"
            @blur="validateField('confirmPassword')"
          />
        </div>

        <div class="flex flex-col sm:flex-row items-center">
          <AppInput
            v-model="v$.zipCode.$model"
            name="zipCode"
            class="sm:mr-7 mb-10 sm:mb-0 w-full"
            mask="#####"
            placeholder="00000"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.zipCode"
            @focus="handleFocus('zipCode')"
            @blur="validateField('zipCode')"
          >
            <template #label>
              <p class="text-dark-cl-20">ZIP CODE <span class="text-grey-fp-60">(optional)</span></p>
            </template>
          </AppInput>

          <AppInput
            v-model="v$.phone.$model"
            name="phone"
            mask="+1 (###) ###-####"
            placeholder="+1 (777) 777-7777"
            class="sm:ml-7 w-full"
            :size="screen.width < 640 ? 'medium' : 'large'"
            :error="errorMessages.phone"
            @focus="handleFocus('phone')"
            @blur="validateField('phone')"
          >
            <template #label>
              <p class="text-dark-cl-20">Mobile Phone Number <span class="text-grey-fp-60">(optional)</span></p>
            </template>
          </AppInput>
        </div>

        <!-- A C T I O N S -->
        <AppButton
          type="primary"
          :size="screen.width < 640 ? 'medium' : 'large'"
          class="w-full mb-20 mt-35"
          data-test="confirm-button"
        >
          Confirm
        </AppButton>

        <div class="flex items-center justify-center text-sm sm:text-base mb-20">
          <p class="text-grey-fp-60">
            Already have an account? <router-link
              :to="{ name: 'Login' }"
              class="text-primary"
            >
              Log in
            </router-link>
          </p>
        </div>
      </form>
    </div>

    <!-- V E R I F I C A T I O N  S T E P -->
    <Verification
      v-else
      :user-email="state.email"
      show-change-email-handler
      @change-email-click="handleClickChangeEmail"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from "@vuelidate/validators";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import Verification from '@/views/auth/components/Verification.vue';

  import { IErrorFields, ISignUpForm, IAppInputError } from '@/types';
  import { signUpValidationRules } from '@/views/auth/auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'SignUp',

    components: { AppInput, AppButton, Verification },

    setup() {
      const router = useRouter();
      const screen = useScreen();

      const state = reactive<ISignUpForm>({
        password: '',
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        phone: '',
      });

      const errorMessages = reactive<IErrorFields>({
        password: '',
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        phone: '',
      });

      const rules = computed<any>(() => ({
        ...signUpValidationRules,
        confirmPassword: { sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(state.password)) }
      }));

      const v$ = useVuelidate(rules, state);

      let step = ref<number>(1);
      let customError = reactive<IAppInputError>({ message: '', link: { name: '', message: '' } });

      let showVerification = ref<boolean>(false);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.password.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.password.minLength.$invalid && v$.value.password.$model },
        { message: 'At least one Number', matched: !v$.value.password.containsNumber.$invalid }
      ]);

      async function onSubmit() {
        if (await v$.value.$validate()) {
          return await authService.signUp(state)
            .then(() => showVerification.value = true)
            .catch((err: any) => handleErrors(err));
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }

      }

      function handleErrors(err: any) {
        if (err.code === 'UsernameExistsException') {
          customError.message = 'This Email is already registered!';
          customError.link = { name: 'Login', message: 'Log in now? ' };
        } else {
          customError = !err.message ? err : err.message;
        }
      }

      function resetCustomError() {
        customError.message = '';
        customError.link = { name: '', message: '' };
      }

      function handleFocus(name: string) {
        if (customError) {
          resetCustomError();
        }
        clearErrorField(name, errorMessages);
      }

      function handleClickChangeEmail() {
        showVerification.value = false;
        state.password = '';
        state.confirmPassword = '';
      }

      return {
        customError,
        clearErrorField,
        errorMessages,
        showVerification,
        router,
        state,
        step,

        screen,
        v$,
        hintOptions,

        resetCustomError,
        handleFocus,
        handleClickChangeEmail,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
      };
    }
  });
</script>
