
  import { defineComponent, reactive, computed, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from "@vuelidate/validators";
  import { useScreen } from 'vue-screen';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import Verification from '@/views/auth/components/Verification.vue';

  import { IErrorFields, ISignUpForm, IAppInputError } from '@/types';
  import { signUpValidationRules } from '@/views/auth/auth-validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';

  export default defineComponent({
    name: 'SignUp',

    components: { AppInput, AppButton, Verification },

    setup() {
      const router = useRouter();
      const screen = useScreen();

      const state = reactive<ISignUpForm>({
        password: '',
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        phone: '',
      });

      const errorMessages = reactive<IErrorFields>({
        password: '',
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        zipCode: '',
        phone: '',
      });

      const rules = computed<any>(() => ({
        ...signUpValidationRules,
        confirmPassword: { sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(state.password)) }
      }));

      const v$ = useVuelidate(rules, state);

      let step = ref<number>(1);
      let customError = reactive<IAppInputError>({ message: '', link: { name: '', message: '' } });

      let showVerification = ref<boolean>(false);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.password.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.password.minLength.$invalid && v$.value.password.$model },
        { message: 'At least one Number', matched: !v$.value.password.containsNumber.$invalid }
      ]);

      async function onSubmit() {
        if (await v$.value.$validate()) {
          return await authService.signUp(state)
            .then(() => showVerification.value = true)
            .catch((err: any) => handleErrors(err));
        } else {
          handleSetErrors(v$.value.$errors, errorMessages);
        }

      }

      function handleErrors(err: any) {
        if (err.code === 'UsernameExistsException') {
          customError.message = 'This Email is already registered!';
          customError.link = { name: 'Login', message: 'Log in now? ' };
        } else {
          customError = !err.message ? err : err.message;
        }
      }

      function resetCustomError() {
        customError.message = '';
        customError.link = { name: '', message: '' };
      }

      function handleFocus(name: string) {
        if (customError) {
          resetCustomError();
        }
        clearErrorField(name, errorMessages);
      }

      function handleClickChangeEmail() {
        showVerification.value = false;
        state.password = '';
        state.confirmPassword = '';
      }

      return {
        customError,
        clearErrorField,
        errorMessages,
        showVerification,
        router,
        state,
        step,

        screen,
        v$,
        hintOptions,

        resetCustomError,
        handleFocus,
        handleClickChangeEmail,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
      };
    }
  });
